import React, { Component } from 'react';
import { Img } from '../UI/UI';

export const SiteLogo = (props) => {
  return(
      <div className="site-logo">
        <a target={"_blank"} href={props.link}>
          <Img desktopImg={props.img} isExternalLink={true} />
        </a>
      </div>
  )
}