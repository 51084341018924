/*
 * cleanStorageToken 
 * delete the token key 
*/
export const cleanStorageToken = () => {
	localStorage.removeItem('token');
} 
/*
 * createStorageToken 
 * get the token from local storage
 
 * @param token string  API token from the server
*/
export const createStorageToken = ( token ) => {
	localStorage.setItem("token", token);
}     
/*
 * getStorageToken 
 * get the token from local storage
 * return null || token
*/
export const getStorageToken = () => {
	return localStorage.getItem("token"); 
}  