import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import Http from '../../Axios/Axios';

import { getSiteScan } from '../../Utility/Utility';


class SiteScan extends Component {
  state = {
  	siteScan: 0
  }
  constructor(props){
    super(props);
  	
  }

  componentDidMount () {
    var logos = getSiteScan().then( (data) => {
      console.log('SiteScan' , data);
      var data = JSON.parse(data) 
      console.log('data' , data);

      this.setState({
      	siteScan :data.last_scan
      })   
    });
  }


  render() {
  	let html  = null
  	if ( this.state.siteScan !== 0 ) {
  		html =  <div className="Site-scan white">
			     	 	<span>
			         	 	עדכון אחרון:  {this.state.siteScan}
			    	 	</span>
			    	 	
			      </div>
  	}
    return (html);
  }
}

export default SiteScan;
