import React  , { Component } from 'react'
import { withRouter } from "react-router";
import { NavLink } from 'react-router-dom'


import axios from "axios" 
import LazyLoad from 'react-lazyload';
import InfiniteScroll from 'react-infinite-scroller';


import Http from '../../Axios/Axios';
import Menu from '../../Components/Menu/Menu'
import Footer from '../../Components/Footer/Footer'
import { TopPart, Img, ReadMore , HomePostItem} from '../../Components/UI/UI';
import Banner  from '../../Components/Banner/Banner';
import Loader  from '../../Components/Loader/Loader';
import { limitText, getPosts , getBanners , getSticky , handleError } from '../../Utility/Utility';

  
import "./Home.css"


const  isIdInArray = (array, id) =>  {
  
  for (var i = 0; i < array.length; i++) {
    if (array[i] === null){
      return false;
    }
    if (array[i].id === id){
      return true;
    }
  }
  return false;
}




class Home extends Component {
  state = {
    hasMore: true,
    page : 0,
    post_per_page:2, // this is for the post pagination 
    post_offset : 0, // this is for the post pagination
    banner_per_page:1, // this is for the post pagination 
    banner_offset : 0, // this is for the post pagination
    sys_post_offset : 0, // this is for the post pagination
    posts: [],
    topRightBanner:[],
    topLeftBanner:[],
    hasBanners:true,
    stickyPost: [],
    sysPostArray: [],

  }

  getDataFromServer = () => {
    var search = this.props.match.params.search;
    var ctgID = this.props.match.params.ctgID;

    // let route  = 'posts?categories_exclude=528';
    let route  = 'posts?categories_exclude=528';
    if ( ctgID !==undefined  ) {
        route = 'posts?categories='+ctgID
    }else if ( search !== undefined ) {
        route = 'posts?search='+search
    }

    Promise.all([ getPosts(route , {
      
      per_page:this.state.post_per_page,
      offset:this.state.post_offset,
    
    }),

    getBanners({
        
        meta_value: 'homepage',
        meta_key: 'banner_area',
        per_page:this.state.banner_per_page,
        offset:this.state.banner_offset,

    }) ,

    getPosts('posts?categories=528' , {
      
        per_page:1,
        offset:this.state.sys_post_offset,
      
      }),

      getBanners({
          
            meta_value: 'topLeft',
            meta_key: 'banner_area',
            per_page:1,
            offset:0,

      }),

      getBanners({
          
            meta_value: 'topRight',
            meta_key: 'banner_area',
            per_page:1,
            offset:0,

      }),


    ]  ).then( ( response ) => {
      
        var posts  = response[0];
        var banner = JSON.parse(response[1]);      
        var sysPost = response[2];      
        var topLeftBanner = JSON.parse(response[3]);      
        var topRightBanner = JSON.parse(response[4]);      


      console.log('banner' , banner);
        if ( typeof banner === 'object' && banner.length === 0  ) {
          // alert('ddd');
          banner = null;
        }

        if ( typeof topLeftBanner === 'object' && topLeftBanner.length === 0  ) {
          // alert('ddd');
          topLeftBanner = null;
        }


        if ( typeof topRightBanner === 'object' && topRightBanner.length === 0  ) {
          // alert('ddd');
          topRightBanner = null;
        }

       
        // var banners  =  response[1][0];
        var newOffset = ( this.state.post_offset ) + 1 ;
        var newBannerOffset = this.state.banner_offset + 1 ;

        var new_sys_post_offset =  this.state.sys_post_offset  + 1; 


        const mixPosts = [
          ...posts,
          ...sysPost,
          banner

        ];

        console.log('mixPosts' , mixPosts);
        
        let counter = 0;
        const uniquePosts = mixPosts.map( ( o ) => {
            if ( o === null ) {
              return;
            }
            const isInArray  = isIdInArray( this.state.posts , o.id);
            if ( isInArray === false ) {
              return o;
            }else {
              return;
            }
        })

        console.log('uniquePosts' , uniquePosts);


        // console.log
        var newPosts = [
            ...this.state.posts ,
            ...uniquePosts,
          
        ];


        // remove undefined and null
        newPosts = newPosts.filter(function (el) {
          return el != null;
        });

        
        


        let hasMore = true;
       
        if ( posts.length === 0 ) {
          hasMore = false;
        }
        this.setState({
          post_offset : newOffset + this.state.post_per_page,
          banner_offset : newBannerOffset,
          sys_post_offset : new_sys_post_offset,
          sysPostArray : sysPost,
          hasMore: hasMore, 
          posts: newPosts,
          topRightBanner,
          topLeftBanner
        })
    });
  }
  componentDidMount () {
    
   
   window.setTimeout(()=>{


   },2000);
   
  }


  render(){
    const stickyPost = this.state.stickyPost;
  
    const topRightBanner = this.state.topRightBanner;
    let topRightBannerHTML = null;

    if ( topRightBanner != null &&  topRightBanner.hasOwnProperty("WP_banner_data") ) {

      topRightBannerHTML =  <Banner  id={topRightBanner.WP_banner_data.post.ID} 
                                link={topRightBanner.WP_banner_data.banner.link}
                                type={topRightBanner.WP_banner_data.banner.type} 
                                height={topRightBanner.WP_banner_data.banner.height} 
                                width={topRightBanner.WP_banner_data.banner.width} 
                                linkToFile={topRightBanner.WP_banner_data.banner.linkToFile} />
    }

    const topLeftBanner = this.state.topLeftBanner;
    let topLeftBannerHTML = null;

    if (  topLeftBanner != null  && topLeftBanner.hasOwnProperty("WP_banner_data") ) {

      topLeftBannerHTML =  <Banner  id={topLeftBanner.WP_banner_data.post.ID} 
                                link={topLeftBanner.WP_banner_data.banner.link}
                                type={topLeftBanner.WP_banner_data.banner.type} 
                                height={topLeftBanner.WP_banner_data.banner.height} 
                                width={topLeftBanner.WP_banner_data.banner.width} 
                                linkToFile={topLeftBanner.WP_banner_data.banner.linkToFile} />
    }

    const posts = this.state.posts.map( ( o , i  ) => {
      let postType  = 'post';
      let cssClass  = '';
      let html      = null ;
      let imgToShow = null;
      let link      = '';
      let post      = '';
      let postMetaData = '';
      let limitTextLength = 40;
      let limitTitleLength = 60;
      let ctgLink = '';
        
      const imgCssClass ='main-img-cover';
      

      if ( o === undefined || o==null )  {
        return;
      }

      if ( o.hasOwnProperty('WP_banner_data') ) {
        postType = 'banner' 
      }

      if ( postType === 'post' ) {

        var postMetaObj =  JSON.parse(o.WP_post_metadata);
        postMetaData    = postMetaObj.postMetaData; 
        post            = postMetaObj.post; 
        cssClass        = '';
        imgToShow       = postMetaObj.post.homeSmallImage;

        if ( i % 2 === 0 ) {
          cssClass=' last';
        }
     
        if ( i === 0 || i % 3 == 0 ) {
          cssClass='home-post-full';
          imgToShow = post.homeBigImage;
        }
        link = postMetaData.link;

        if ( postMetaData.isExternalLink === false ) {
          link = '/Posts/' + post.ID;
        }

      
        if ( postMetaData.hasOwnProperty("ctg") ) {
          ctgLink = '/search/ctg/' +  postMetaData.ctg.ID;
        }

      }
      
    
      
      if ( o.hasOwnProperty('WP_banner_data') ) {
        const bannerObj = o.WP_banner_data;

        html = <Banner  id={bannerObj.post.ID}
                        height={bannerObj.banner.height} 
                        width={bannerObj.banner.width} 
                        link={bannerObj.banner.link}
                        type={bannerObj.banner.type} 
                        linkToFile={bannerObj.banner.linkToFile} />
      }else {
         
            html = <HomePostItem  link={link} 
                                  id={post.ID}
                                  cssClass={cssClass}
                                  imgCssClass={imgCssClass}
                                  imgToShow={imgToShow}
                                  title ={post.title}
                                  content ={post.WP_post_raw.replace(/<(?:.|\n)*?>/gm, '')}
                                  date_post_created={postMetaData.date_post_created}
                                  site={postMetaData.site}
                                  limitTextLength={limitTextLength}
                                  limitTitleLength={limitTitleLength}
                                  ctgLink={ctgLink} 
                                  isExternalLink={postMetaData.isExternalLink}
                                  />
        
      }

      return (html)
    })

    const stikeyHTML = stickyPost.map((o)=>{
      
    
      let link = '';
      let cssClass = 'home-post-full';
      let postMetaData = '';
      let limitTextLength = 60;
      let limitTitleLength = 60;
      let ctgLink = '';
      
    
      postMetaData    = o.postMetaData; 
      const post            = o.post; 
      const imgToShow       = post.homeBigImage;
      const imgCssClass ='main-img-cover';

     
      return <HomePostItem  link={link} 
                                  id={post.ID}
                                  cssClass={cssClass}
                                  imgCssClass={imgCssClass}
                                  imgToShow={imgToShow}
                                  title ={post.title}
                                  content ={post.WP_post_raw}
                                  date_post_created={postMetaData.date_post_created}
                                  site={postMetaData.site}
                                  limitTextLength={limitTextLength}
                                  limitTitleLength={limitTitleLength}
                                  ctgLink={ctgLink} 
                                  isExternalLink={postMetaData.isExternalLink}
                                  />
    })
  
    
    return(


      <div className="page align-center page-home">        
        {<Menu />}
        <TopPart />
        <section id="home-posts">
          <div id="top-right-banner">
            {topRightBannerHTML}
          </div>
          <div id="top-left-banner">
            {topLeftBannerHTML}
          </div>
          <div className="container">
            <div className="sticky-Post">
                  {stikeyHTML}
            </div>
          </div>

          
          <div className="container fix-height">
              <InfiniteScroll
                  pageStart={0}
                  loadMore={this.getDataFromServer}
                  hasMore={this.state.hasMore}
                  loader={ <Loader /> } >
               {posts}
              </InfiniteScroll>
          </div>
        </section>
        <LazyLoad height={200} once offset={400}>
          <Footer />
        </LazyLoad>
      </div>
    )
  }

}



export default withRouter(Home)