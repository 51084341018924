import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import axios from 'axios';


import './HeaderDate.css';

class HeaderDate extends Component {
  state = {
    date:{
      day: '' , 
      month: '' , 
      year : '' ,
      dayInWeek: ''
    },
  	hebrewDate : ''

  }

  getDateObj(){
    var days = ['ראשון','שני','שלישי','רביעי','חמישי','שישי','שבת'];
    var date = new Date();
    var day = date.getDate() ;
    var month = date.getMonth();
    var year = date.getFullYear();
    var dayInWeek = days[date.getDay()];
    month = month + 1;
    if ( month < 10 ) {
      month = '0' + month; 
    } 
    if ( day < 10 ) {
      day = '0' + day; 
    } 

    return {
      day,
      month,
      year,
      dayInWeek,
    }
  }
  constructor(props){
    super(props);

  	
  }

  componentDidMount () {

    var dateObj = this.getDateObj();
    
    /**
     * Dont want to overkill the API 
     * This sould go  get the date once per user 
     * @todo create route for this, so  i will have more control
     * posbllie memcacheD   
    */

    if ( parseInt(dateObj.day) !== parseInt(localStorage.getItem('day')) ) {
      // alert('ddd');
      axios.get('https://www.hebcal.com/converter/?cfg=json&gy='+dateObj.year+'&gm='+dateObj.month+'&gd=' + dateObj.day).then((response) => {
        console.log(response.data);
        const hebrewDate = response.data.hebrew;

        localStorage.setItem('day' , dateObj.day);
        localStorage.setItem('hebrewDate' , hebrewDate);
        this.setState({
          hebrewDate , 
          date : {
            day: dateObj.day , 
            month: dateObj.month , 
            year: dateObj.year ,
            dayInWeek: dateObj.dayInWeek
          }
        })

      })
      .catch(function (error) {
        console.log(error);
      });
    }else {
      this.setState({
        hebrewDate: localStorage.getItem('hebrewDate') , 
        date : {
          day: dateObj.day , 
          month: dateObj.month , 
          year: dateObj.year ,
          dayInWeek: dateObj.dayInWeek
        }
      })
    }

	  
  }


  render() {
  
    return (
      <div className="Header-date">
 	 	<h1>
     	 	<span className="day-in-the-week smaller white">
     	 		יום {this.state.date.dayInWeek}
     	 	</span>
     	 	<span className="clear"></span>
     	 	<span className="num-date">
     	 		{this.state.date.day+'.'+this.state.date.month}
     	 	</span>
     	 	<span className="clear"></span>
     	 	<span className="heb-date smaller white">
     	 		{this.state.hebrewDate}
     	 	</span>
     	 	
	 	</h1>
	 	
      </div>
    );
  }
}

export default HeaderDate;
