import React from 'react'
import { Route ,Switch } from 'react-router-dom'


// pages
import Home from '../Pages/Home/Home';


// Posts
import Posts from '../Posts/Posts/Posts';

/*
 * Defing client route
 * 
*/
export const Routes = () => (
	<Switch>
		<Route  path="/Posts/:id" component={Posts} />
		<Route  path="/search/ctg/:ctgID" component={Home} />
		<Route  path="/search/:search" component={Home} />
		<Route  path="/" component={Home} />
	</Switch>

);

