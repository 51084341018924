import axios from 'axios';
import { handleError , API_URL , API_HOST} from '../Utility/Utility';
import { cleanStorageToken, 
		 createStorageToken,
		 getStorageToken } from '../LocalStorage/LocalStorage';



/*
 * getToken 
 *  
 * get token from JWT WP plugin
 * 
 * make a POST requset  to API_HOST + 'wp-json/jwt-auth/v1/token'
 * @link https://wordpress.org/plugins/jwt-authentication-for-wp-rest-api/ 
 *  
 * return (string) token on succses 
*/
const getToken = async () => {
	// get token from Local Storage
	var token = getStorageToken();
	// console.log('token' , token);
	
	cleanStorageToken();
	// // alert('Here');
	if ( token === undefined || token === '' || token === null   ){
		try {
			const req = await axios.get(API_HOST + 'wp-json/API/v1/users/1/getToken');
			const tokenFromServer = req.data.token;
			// create token on Local Storage
			createStorageToken(tokenFromServer);
			return tokenFromServer;
		} catch(err) {
			handleError(err); // TypeError: failed to fetch
		}

	}
	return new Promise(resolve => {
  		resolve(token);
 	});

}


class AuthHttp  {

	constructor(){
		// ajax counter 
		this.counter = 0;
	}
	/*
	 * makeAnAuthReq 
	 *  
	 * make an auth requset to the wordpress REST API
	 * POST / route
	 * @param (string) route / which route to go    
	 * @param (Object) params  / data to send 
	 * @param (function) callback  // callback on success
	*/
	makeAnAuthReq( route , params, callback = () => {} ){
		
		if ( route !== undefined && params !== undefined ) {
		
		var tokenPromise =  getToken();

		// console.log(tokenPromise);
		tokenPromise.then( token => {
			console.log('token' , token);
			// alert(token);
			const options = {
				method: 'POST',
				headers: { 
					'content-type': 'application/json' ,
					'Authorization': "Bearer  " + token
				},
				data: JSON.stringify(params),
				url : API_URL + '' + route  , 
			};

			axios(options).then( (response) =>  {
				console.log(response);
				if ( response.status === 401 ) {
					this.makeAnAuthReq(route , params);
				}
				callback(response);
			})
			.catch( (error) => {
				
				var doAuth = false;
				var patt = new RegExp("401");
				var res = patt.test(error);

				if ( res === true )  {
					doAuth = true ;
				}
				// incrase counter 
				this.incarseCounter();
				// limit the request 
				// prevent infinty loop
				if ( this.getCounter() <= 2 ) {

					// on erroe clear token
					cleanStorageToken();
					// handle ajax error
					handleError(error);
					// Try to make the same request again
					// This is good if we are getting a 403 response  or 401
					// From the WP rest API , So we need to get anoter token.
					this.makeAnAuthReq(route , params);
					
				}else {
					// alert("no more");
				}
			});

		})
		}

	}

	/*
	 * getCounter 
	 *  
	 * return ( int ) this.counter

	*/
	getCounter(){
		return this.counter;
	}
	/*
	 * incarseCounter 
	 *  
	 * incarse Counter by one

	*/
	incarseCounter(){
		this.counter++;
	}


}

export default AuthHttp;
