import React  , { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Menu from '../../Components/Menu/Menu'
import Footer from '../../Components/Footer/Footer'
import { TopPart, Img, ReadMore, SingleComment, HomePostItem} from '../../Components/UI/UI';
import CommentsForm from '../../Components/CommentsForm/CommentsForm';
import LeadForm from '../../Components/LeadForm/LeadForm';
import AuthHttp from '../../Axios/AuthHttp';
import { limitText, getPostsById, getPostComments , handleError, getBanners} from '../../Utility/Utility';


import Banner  from '../../Components/Banner/Banner';
import "./Posts.css"




class Posts extends Component {
  state = {
    id:this.props.match.params.vidId,
    ctgId: this.props.match.params.ctgId,
    postData : {

    },

    topRightBanner:[],
    topLeftBanner:[],
    postAutor : {},
    comments:[],
    relatedPosts : [],
    post_view : 0,
    after_form_html : ''
  } 

 

  componentDidMount() {
  
    const id = this.props.match.params.id;
     Promise.all([
        getPostsById(id) , 
        getPostComments({
          post : id
        }),
        // getBanners({
            
        //       meta_value: 'topLeft',
        //       meta_key: 'banner_area',
        //       per_page:1,
        //       offset:0,

        // }),

        // getBanners({
            
        //       meta_value: 'topRight',
        //       meta_key: 'banner_area',
        //       per_page:1,
        //       offset:0,

        // })
        ])
        .then( ( response ) => {

          const currentPost = response[0];
          const contentHTML    = response[0].content.rendered;
          const comments    = response[1];
          const after_form_html = currentPost.after_form_html;

          // console.log('currentPost' , currentPost);
          // var topLeftBanner = JSON.parse(response[2]);      
          // var topRightBanner = JSON.parse(response[3]);      


          // if ( typeof topLeftBanner === 'object' && topLeftBanner.length === 0  ) {
          //   // alert('ddd');
          //   topLeftBanner = null;
          // }


          // if ( typeof topRightBanner === 'object' && topRightBanner.length === 0  ) {
          //   // alert('ddd');
          //   topRightBanner = null;
          // }

          this.setState({
            postData: JSON.parse(currentPost.WP_post_metadata),
            comments,
            postAutor : JSON.parse(currentPost.WP_post_author),
            contentHTML,
            relatedPosts :JSON.parse(currentPost.WP_related_posts),
            post_view : ( currentPost.post_view === false ) ? 0 :  currentPost.post_view ,
            after_form_html: JSON.parse(after_form_html)
          // topRightBanner,
          // topLeftBanner
          });
        })
        .catch((err) => {
          handleError(err);
    })

    var Auth = new AuthHttp();
    Auth.makeAnAuthReq('posts/'+id+'/' , {
      post_view : true 
    }, () => {
    
    });
  }



   setPostHTML = (contentHTML) => {
  
       return { __html: contentHTML};
  }

  render(){

      const postData = this.state.postData;
      const postAutor = this.state.postAutor;
      const postComments = this.state.comments;
      const relatedPosts = this.state.relatedPosts;
      const contentHTML = this.state.contentHTML;

      console.log('postData', postData);
      // let postCommentsHTML = null;


      // to do make this to component
      const postCommentsHTML = postComments.map( (comment , index) => {
        const counter = index + 1;
        return <SingleComment counter={counter} 
                              author_name={comment.author_name}
                              comment={comment.WP_comment_raw} /> 
      })

      const relatedPostsHTML = relatedPosts.map( (relatedPosts , index) => {
        
        console.log('relatedPosts' , relatedPosts);
        const post =   relatedPosts.post;
        const postMetaData =   relatedPosts.postMetaData;
        let postType  = 'post';
        let cssClass  = '';
        let html      = null ;
        let imgToShow = post.homeSmallImage;     
        let limitTextLength = 60;
        let limitTitleLength = 60;
        let ctgLink = '';
        const link = postMetaData.link;
        const imgCssClass ='main-img-cover';
        const counter = index + 1;

        return <HomePostItem    
                  link={link}
                  id={post.ID}
                  cssClass={cssClass}
                  imgCssClass={imgCssClass}
                  imgToShow={imgToShow}
                  title ={post.title}
                  content ={post.content}
                  date_post_created={postMetaData.date_post_created}
                  site={postMetaData.site}
                  limitTextLength={limitTextLength}
                  limitTitleLength={limitTitleLength}
                  ctgLink={ctgLink} 
                  isExternalLink={postMetaData.isExternalLink} />
      })
    
      let postBody = null;
      let footerHTML = null;
      let commentsHTML = null

      let relatedPostsHT = null;
      if ( postData.hasOwnProperty('post') ) {
          
          let leadFormHTML = null;
          footerHTML = <Footer />;

          commentsHTML = 
            <section className="comments">
              <div className="container">
                <h3>
                  תגובות לכתבה
                </h3>

                <CommentsForm id={this.props.match.params.id} />
                {postCommentsHTML}
              </div>
            </section>

          relatedPostsHT = 
          <section className="related-posts">
            <div className="container">
              <h3>
                אולי יעניין אותך גם
              </h3>
              <div className="related-posts-wrapper">
                 {relatedPostsHTML}
              </div>
            </div>
          </section>
          if ( postData.postMetaData.show_lead_form === '1' ) {
            let formText = 'רוצים לשמוע עוד?';
           
            if ( postData.postMetaData.form_text !== false ) {
              formText = postData.postMetaData.form_text;
            }
            leadFormHTML =  <div className="lead-form-wrapper">
                              <h3>  
                                {formText} 
                              </h3>
                              <LeadForm cmpCode={postData.postMetaData.lead_sys_cmp_code} postId={this.props.match.params.id} />
                            </div>
          }
          const post = postData.post;
          postBody = 
            <div className="inner">
                <div className="post-meta col-50 add-side-padding">
                  <div className="post-date ">
                    {postData.postMetaData.date_post_created}
                  </div>

                  <div className="post-date ">
                    מספר צפיות: {this.state.post_view}
                  </div>
                  
                  <div className="bottom-post-info "></div>
                </div>
                <div className="col-50"></div>
                <div className="clear"></div>
                <div className="post-title add-side-padding">
                  <h1>
                      {post.title}
                      <span className="post-autor">
                      {postAutor.autor.nickname}
                      </span>
                  </h1>
                </div>
                <Img desktopImg={postData.post.postMainImage} cssClass={'img-responsive'}  isExternalLink ={true} />
                <div className="post-content ">
                  <div dangerouslySetInnerHTML ={this.setPostHTML(contentHTML)} ></div>
                </div>
                <div className="clear"></div>
               
                {leadFormHTML}
                <div className="clear"></div>

                  <div  className="post-content after-post-content" dangerouslySetInnerHTML ={this.setPostHTML(this.state.after_form_html)} ></div>
                
            </div>
      }



    const topRightBanner = this.state.topRightBanner;
      let topRightBannerHTML = null;

      if ( topRightBanner != null &&  topRightBanner.hasOwnProperty("WP_banner_data") ) {

        topRightBannerHTML =  <Banner  id={topRightBanner.WP_banner_data.post.ID} 
                                  link={topRightBanner.WP_banner_data.banner.link}
                                  type={topRightBanner.WP_banner_data.banner.type} 
                                  height={topRightBanner.WP_banner_data.banner.height} 
                                  width={topRightBanner.WP_banner_data.banner.width} 
                                  linkToFile={topRightBanner.WP_banner_data.banner.linkToFile} />
      }

      const topLeftBanner = this.state.topLeftBanner;
      let topLeftBannerHTML = null;

      if (  topLeftBanner != null  && topLeftBanner.hasOwnProperty("WP_banner_data") ) {

        topLeftBannerHTML =  <Banner  id={topLeftBanner.WP_banner_data.post.ID} 
                                  link={topLeftBanner.WP_banner_data.banner.link}
                                  type={topLeftBanner.WP_banner_data.banner.type} 
                                  height={topLeftBanner.WP_banner_data.banner.height} 
                                  width={topLeftBanner.WP_banner_data.banner.width} 
                                  linkToFile={topLeftBanner.WP_banner_data.banner.linkToFile} />
      }

      const post  = 
        <div className="inner-post post ">
           <TopPart />
            <div id="top-right-banner">
            {topRightBannerHTML}
          </div>
          <div id="top-left-banner">
            {topLeftBannerHTML}
          </div>
            <section className="post-data">
              <div className="container">
                <div className="border-raduis-box post-content col-100">
                  {postBody}
                  <div className="clear"></div>
                </div>

                <div className="red-border"></div>
                
                <div className="clear"></div>
              </div>
            </section>

            <div>

              {relatedPostsHT}

              {commentsHTML}
              
            </div>
            {footerHTML}
          
        </div>

    return (post)
  }

}



export default withRouter(Posts)