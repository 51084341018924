import React, { Component } from 'react';
import AuthHttp from '../../Axios/AuthHttp';
import { Input, Img } from "../UI/UI"

import { validateFields } from "../../Utility/Validator/Validator"
import './CommentsForm.css';

class CommentsForm extends Component {
  state = {

    isFormSend:false,
    showErr:false,
    fields : [
        {
          name : 'commentUserName',
          type : "text" ,
          validationType:"simple",
          cssClass : "clean-input",
          placeholder : "יש להבניס שם",
          val:"",
          err_1: "יש להכניס שם"
        },

        {
          name : 'commentEmail',
          type : "text" ,
          validationType:"email",
          cssClass : "clean-input",
          placeholder : "הכנס אימייל:",
          val:"",
          err_1: "יש להכניס אימייל",
          err_1: "אימייל לא תקין",
        },
        {
          name : 'commentText',
          type : "text" ,
          validationType:"simple",
          cssClass : "clean-input",
          placeholder : "כתוב תגובה:",
          val:"",
          err_1: "שדה תגובה שדה חובה"
        },

    ]
  }

  constructor(props){
    super(props);
  }

  componentDidMount () {
	
  }

  handleSubmit = (event) => {
    event.preventDefault();

    var Auth = new AuthHttp();
    
    const isValid = validateFields(this.state.fields);

    if ( isValid ) {

      Auth.makeAnAuthReq('comments/' , {
        author_name : this.state.fields[0].val,
        author_email : this.state.fields[1].val , 
        content : this.state.fields[2].val,
        post : this.props.id,
      }, (res) => {
        console.log(res);
        if ( res.status === 201  && res.statusText === "Created" ) {
          alert('תודה רבה שהשארת  תגובה... נציג מטעמנו יעבור על התגובה.');
        }else {
          alert('לא ניתן לשמור תגובה... אנא נסה מאורח יותר')
        }
        // window.open(link , '_blank');
      });
      
    }
    
  }



  setFieldState ( name , value)  {
    const fields  = [...this.state.fields];
      // find the cureent field
      const field = fields.find( field => {
         return field.name === name
      });
      // console.log("field", field);
      // change the value 
      field.val = value;
      this.setState({ fields });
  }

  handleUserInput = ( event ) => {
    const target  = event.target; 
    const name    = target.name;
    const value   = target.value;
    this.setFieldState( name , value );
  }

  clean = () => {
      this.setFieldState( 'commentUserName' , '' );
      this.setFieldState( 'commentEmail' , '' );
      this.setFieldState( 'commentText' , '' );
  } 
  render() {
  	let showErrHTML = null;
    if ( this.state.showErr ) {
      showErrHTML = <span className="red">{this.state.fields[0].err_1}</span>
    }
    const formFields = this.state.fields.map((obj , index) => {
        return <Input key={index} type={obj.type}
                validationType={obj.validationType} 
                placeholder={obj.placeholder}
                name={obj.name} 
                val={obj.val}
                inputCssClass={obj.cssClass}
                change={this.handleUserInput} /> 
        
    });
    return (

      <div className="Comments-form">
 	 	    <form  onSubmit={this.handleSubmit}>
          { formFields }
          <button type="button" className="comments-button clean" onClick={this.clean}>
            <Img desktopImg={'x.png'} />
          </button>

          <button className="comments-button send">
            <Img desktopImg={'btn.png'} />
          </button>
          
          {showErrHTML}
        </form>
      </div>
    );
  }
}

export default CommentsForm;
