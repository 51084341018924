/*
 * Validator Class 
 * @DESC  simplae validation class
*/
class Validator {
	simpleValidation(o) {
	    if (o.val === '') {
	        this.show_popup(o.err_1);
	        return false;
	    } else {
	        return true;
	    }
	}
	// email validation 
	email_validation(o) {
	  if (o.val === "") {
	        this.show_popup(o.err_1);
	        return false;
	  } else {
        if (o.expression.test(o.val) === false) {
            this.show_popup(o.err_2);
            return false;
        } else {
            return true;
        }

	  }

	}
	// regx validation 
	regx_validation( obj ) {
	    if ( obj.val === '' ) {
	        this.show_popup(obj.err_1);
	        return false;
	    } else {
	        if (obj.expression.test(obj.val)) {
	            return true;
	        } else {
	            this.show_popup(obj.err_2);
	            return false;
	        }
	    }
	}
	// alert 
	show_popup(msg) {
	    alert(msg);
	    
	};
}

export default Validator ;


export const validateFields = ( fields ) => {
	let errors = [];
	var v = new Validator();
	for ( let i in fields ) {
		const currentField = fields[i]; 
		const  validationType = currentField.validationType;
		let res = null
		if ( validationType === "simple" ) {
			res = v.simpleValidation({
				val : currentField.val ,
				err_1 : currentField.err_1
			});
		} else if ( validationType === "email"  ) {
			res = v.regx_validation({
				val : currentField.val ,
				err_1 : currentField.err_1,
				err_2 : currentField.err_2,
				expression: /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
			});
		} else if ( validationType === "phone"  ) {
			res = v.regx_validation({
				val : currentField.val ,
				err_1 : currentField.err_1,
				err_2 : currentField.err_2 ,
				expression: /^[0-9]{9,11}$/
			});
		}else if ( validationType === "number" ){
			res = v.regx_validation({
				val : currentField.val ,
				err_1 : currentField.err_1,
				err_2 : currentField.err_2 ,
				expression: /^[0-9]+$/
			});
		}else if ( validationType === "licence" ){
			res = v.regx_validation({
				val : currentField.val ,
				err_1 : currentField.err_1,
				err_2 : currentField.err_2 ,
				expression: /^[0-9]+(-?[0-9]+)+$/
			});
		}
		if ( res !== true ) {
			errors.push("err")
			currentField.cssClass =  currentField.cssClass+" err-border ";
		}else {
			currentField.cssClass = currentField.cssClass + "succses-border";
		} 
	}

	// check to  see if error acourd
	if ( errors.length === 0 ) {
		return true;
	}else {
		return false;
	}

}