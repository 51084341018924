import React, { Component } from "react" 
import { NavLink } from 'react-router-dom'


import "./AgreeCookies.css"



class AgreeCookies extends Component {
  state = {
  	isOpen : true
  }
  setCookie = () => {
  	document.cookie = "agreetoacookie=true";
  	this.setState({
  		isOpen:false
  	})
  }

  checkCookie = () => {
  	let hasCookie = false;
  	var document_cookies = document.cookie;
	document_cookies = document_cookies.split(";");
	// console.log(document_cookies);
	for ( var i in document_cookies ) {
			var patt = new RegExp("agreetoacookie");
			var res = patt.test(document_cookies[i]);
			if (res==true){
				hasCookie =  true
			}
	}

	if ( hasCookie === false ) {
		this.setState({
			isOpen:true
		})

	}else {
		this.setState({
			isOpen:false
		})
		
	}
  } 

  render() {
  	let html = "";

  	let hasCookie = false;
  	var document_cookies = document.cookie;
	document_cookies = document_cookies.split(";");
	// console.log(document_cookies);
	for ( var i in document_cookies ) {
			var patt = new RegExp("agreetoacookie");
			var res = patt.test(document_cookies[i]);
			if (res==true){
				hasCookie =  true
			}
	}
	// alert(hasCookie);
  	if (this.state.isOpen===true && hasCookie === false) {
  		html = 
			<div id="cookie-agree" onClick={this.setCookie}>
			    
			    <div className="container">
				    <h3>
				    	באתר זה נעשה שימוש בקבצי cookie כדי לשפר את חווית הגלישה שלכם. 
				    </h3>
				     <p> 
				 		 במידה ותמשיכו להשתמש באתר שלנו,
				 		 אתם מסכימים לשימוש זה.
				     </p>
				    <button>
				    	סגירה
				    </button>
			     </div>
			</div>
  	};
  	
  	return(html)
  }
}

export default AgreeCookies