import React, { Component } from "react" 
import { NavLink } from 'react-router-dom'
import AgreeCookies from '../AgreeCookies/AgreeCookies'
import { Logo } from "../UI/UI";
import "./Menu.css"

class Menu extends Component {
  state = {
    isOpen : false 
  }

  setMenuState = () => {
    this.setState({
      isOpen : !this.state.isOpen
    })
  } 
  render(){

    let cssClass ="inner";

    if ( this.state.isOpen === true ) {
      cssClass += " open"
    }
    return(
      <div className="menu-outer-wrapper">
        <AgreeCookies />
      </div>
    )
  }
}


export default Menu