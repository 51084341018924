import axios from 'axios';

import { API_URL } from '../Utility/Utility';

const Http = axios.create({
  baseURL: API_URL , //'https://digital-cloud.co.il/realestatenews/wp-json/wp/v2/',
  headers: {
  	'content-type': 'application/json' 
  }
});

export default Http  