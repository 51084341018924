import React, { Component } from 'react';
import { Routes } from "./Routes/Routes";
import { isMobile , addFindSupport , getBanners} from './Utility/Utility'
import './App.css' ;

// import Banner  from './Components/Banner/Banner';
import Loader  from './Components/Loader/Loader';
class App extends Component {

  constructor (){
  /*
    * The super keyword call functions on a parent object.
    * @link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/super
  */ 
  super(); // 

  this.state = { 
    timeToHide:500,
    isLoading : true,
    // fullPageBanner:[]
  }
  /*
    * if find is undefined 
    * add the js find function 
  */
  if (!Array.prototype.find) {
    addFindSupport();
  }

  }
  /*
    * hideLoader
    * hide loader on page load
  */
  hideLoader = () => {
    this.setState({
      isLoading:false,

    })
  }

  componentDidMount () {
    // const fullPageBanner = getBanners({
          
    //     meta_value: 'fullpage',
    //     meta_key: 'banner_area',
    //     per_page:1,
    //     offset:0,

    // });

    // fullPageBanner.then((banner)=>{
    //   this.setState({
    //     fullPageBanner: [JSON.parse(banner)]

    //   })
    // })
  }
  /*
   * render
   * @link https://reactjs.org/docs/render-props.html
  */
  render() {

    // const fullPageBanner = this.state.fullPageBanner;
    // const fullPageBannerHTML = fullPageBanner.map((o)=>{
    //   if ( o.hasOwnProperty('WP_banner_data') ) {

    //     return <Banner  id={o.WP_banner_data.post.ID} 
    //                               link={o.WP_banner_data.banner.link}
    //                               type={o.WP_banner_data.banner.type} 
    //                               height={o.WP_banner_data.banner.height} 
    //                              width={o.WP_banner_data.banner.width} 
    //                               linkToFile={o.WP_banner_data.banner.linkToFile} />
        
    //   }

    // }) 
   
    // add cssClass for css purpose
    let cssClass = 'desktopVersion ';
    if ( isMobile() ) {
        cssClass = ' MobileVersion ';
    }
    let html = <div className={'App ' + cssClass}>
                  { window.scrollTo(0, 0) }
                  <Routes />
              </div>

    if (this.state.isLoading === true){
      
      // console.log('fullPageBanner.length', fullPageBanner.length);
      // if ( fullPageBanner.length > 0 ) {
      //   html =  <div className="fullPageBanner">
      //             <div className="close" onClick={this.hideLoader}>X</div>
      //             {fullPageBannerHTML}
      //             האתר יוצג  בעוד X שניות
      //           </div>;
      // }

      // else {
         html = <div  className={'App ' + this.state.currentBrowser }>
                 <div className="loader-main-wrapper">
                   <Loader />
                 </div>
                </div>
        window.setTimeout( ()=>{
          this.hideLoader()
        },this.state.timeToHide) 
      // }
      

    }

    return (html);
  }
}

export default App;
