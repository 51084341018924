import React, { Component } from 'react';

import { Input, Img } from "../UI/UI"
import './SerachForm.css';

class SerachForm extends Component {
  state = {
    isFormSend:false,
    showErr:false,
    fields : [
        {
          name : 'userName',
          type : "text" ,
          validationType:"simple",
          cssClass : "clean-input",
          placeholder : "הכנס מילת חיפוש",
          val:"",
          err_1: "שם מלא הינו שדה חובה"
        },

    ]
  }

  constructor(props){
    super(props);
  }

  componentDidMount () {
	
  }

  handleSubmit = (event) => {
    event.preventDefault();
    window.location.href="/search/" + this.state.fields[0].val
    
  }



  setFieldState ( name , value)  {
    const fields  = [...this.state.fields];
      // find the cureent field
      const field = fields.find( field => {
         return field.name === name
      });
      // console.log("field", field);
      // change the value 
      field.val = value;
      this.setState({ fields });
  }

  handleUserInput = ( event ) => {
    const target  = event.target; 
    const name    = target.name;
    const value   = target.value;
    this.setFieldState( name , value );
  }


  render() {
  	let showErrHTML = null;
    if ( this.state.showErr ) {
      showErrHTML = <span className="red">{this.state.fields[0].err_1}</span>
    }
    const formFields = this.state.fields.map((obj , index) => {
        return <Input key={index} type={obj.type}
                validationType={obj.validationType} 
                placeholder={obj.placeholder}
                name={obj.name} 
                val={obj.val}
                inputCssClass={obj.cssClass}
                change={this.handleUserInput} /> 
        
    });
    return (

      <div className="Serach-form">
 	 	    <form  onSubmit={this.handleSubmit}>
          { formFields }
          <button>
           <Img  desktopImg={'magdelet.png'}/>
          </button>
          {showErrHTML}
        </form>
      </div>
    );
  }
}

export default SerachForm;
