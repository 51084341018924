
import React from "react"
import { Link } from 'react-router-dom'
import { getImgUrl } from '../../Utility/Utility'
import { Img } from '../../Components/UI/UI'
import "./Footer.css"

const Footer = () => {
	return (
		<div className="Footer-outer-wrapper">
			<div className="container">
				<div className="row">
					<Link to="/Posts/17305">תנאי שימוש</Link> |
					<Link to="/Posts/17307">מדיניות פרטיות</Link> |
					<a target="_blank" href="https://digital-cloud.co.il/?utm_source=RealEstate%20News&utm_medium=footer">Powered by משרד דיגיטל קלאוד כל הזכויות שמורות לחדשות הנדל"ן </a>
				</div>

			</div>
		</div>
	)
}
export default Footer
