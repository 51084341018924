import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Http from '../../Axios/Axios';
import AuthHttp from '../../Axios/AuthHttp';

import { Img , BannerIframe  } from '../../Components/UI/UI';
import './Banner.css';




class Banner extends Component {
  state = {

  }
  constructor(props){
    super(props);

  }

  componentDidMount () {
    
  }

  handleClick = (link) => {
    console.log('link',link);
    // alert('d');
    
    var newWindow = window.open('' , '_blank');
    var Auth = new AuthHttp();
    console.log(Auth);
    Auth.makeAnAuthReq('banners/'+this.props.id+'/' , {
      banner_count : 2 
    }, () => {
      newWindow.location = link;
      // window.open(link , '_blank');
    });

  }
  render() {
  	
    const type = this.props.type;
    const link = this.props.link;
    const linkToFile = this.props.linkToFile;
    const height = this.props.height;
    const width = this.props.width;

    let html = null;
    let bannerCssClass = null;
    if ( type === 'img' ) {
      bannerCssClass = 'banner-type-img';
      html = <Img  cssClass={'img-responsive'} click={ () => {
        this.handleClick(link);
      }}  desktopImg={linkToFile} isExternalLink={true} />;
    }else if (type === 'iframe') {
      bannerCssClass = 'banner-type-iframe';

      html = <BannerIframe height={height} click={ () => {
        this.handleClick(link);
      }} src={linkToFile} />;

    }

    return (
      <div className={'Banner-wrapper col-100 ' + bannerCssClass } style = {{
        maxWidth: width  +'px' ,
        maxHeight: height +'px'
      }}>
 	 	    <div  className="">
        {html}
        </div>
      </div>
    );
  }
}

export default Banner;
