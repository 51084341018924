import React, { Component } from 'react';
import AuthHttp from '../../Axios/AuthHttp';
import { Input, Img } from "../UI/UI"

import { validateFields } from "../../Utility/Validator/Validator"
import axios from 'axios';

import './LeadForm.css';

class LeadForm extends Component {
  state = {

    isFormSend:false,
    isSendingForm:false,
    showErr:false,
    fields : [
        {
          name : 'userName',
          type : "text" ,
          validationType:"simple",
          cssClass : "clean-input",
          placeholder : "שם:",
          val:"",
          err_1: "יש להכניס שם"
        },

        {
          name : 'phone',
          type : "text" ,
          validationType:"phone",
          cssClass : "clean-input",
          placeholder : "טלפון:",
          val:"",
          err_1: "שדה טלפון שדה חובה",
          err_2: "טלפון לא תקין"
        },

        {
          name : 'email',
          type : "text" ,
          validationType:"email",
          cssClass : "clean-input",
          placeholder : "אימייל:",
          val:"",
          err_1: "יש להכניס אימייל",
          err_1: "אימייל לא תקין",
        },
        

    ]
  }

  constructor(props){
    super(props);
  }

  componentDidMount () {
	
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const isValid = validateFields(this.state.fields);

    if ( isValid ) {
     
      this.setState({
        isSendingForm:true
      });


      var Media = 'From_Nadlen_News';

      if ( this.props.postId === '8752' ) {
         Media = 'FROM_NADLAN_NEWS_183K';
      } 

      
      axios.post('https://leads.digital-cloud.co.il/WebForm/Lead/v2/' , {

       
        send_lead: true,
        cmp_code: this.props.cmpCode, //this.props.cmp_code,
        postId: this.props.postId, //this.props.cmp_code,
        firstname: this.state.fields[0].val,
        phone: this.state.fields[1].val,
        email: this.state.fields[2].val,
        Media:Media,
        Field_1: window.location.href

      }).then( (res) => {
        // console.log(res);
        // alert('פרטייך התקבלו בהצלחה');
        this.setState({
          isFormSend:true,
          isSendingForm:false
        })

        this.setFieldState( 'userName' , '' );
        this.setFieldState(  'phone' , '' );
        this.setFieldState( 'email' , '' );

        window.setTimeout(()=>{
          this.setState({
            isFormSend:false,
          });
        },2000);

      }).catch((e)=>{
        alert(e);
      })
      
    }
    
  }



  setFieldState ( name , value)  {
    const fields  = [...this.state.fields];
      // find the cureent field
      const field = fields.find( field => {
         return field.name === name
      });
      // console.log("field", field);
      // change the value 
      field.val = value;
      this.setState({ fields });
  }

  handleUserInput = ( event ) => {
    const target  = event.target; 
    const name    = target.name;
    const value   = target.value;
    this.setFieldState( name , value );
  }

  render() {
  	let showErrHTML = null;
    if ( this.state.showErr ) {
      showErrHTML = <span className="red">{this.state.fields[0].err_1}</span>
    }
    if ( this.state.isFormSend === true ) {
         showErrHTML = 
          <span className="green">
           פרטייך התקבלו במערכת ונציג יחזור אליך בהקדם.
          </span>
    }
    const formFields = this.state.fields.map((obj , index) => {
        return <div className="form-field-wrapper">
                <Input key={index} type={obj.type}
                    validationType={obj.validationType} 
                    placeholder={obj.placeholder}
                    name={obj.name} 
                    val={obj.val}
                    inputCssClass={obj.cssClass}
                    change={this.handleUserInput} />
                </div>
            
    });

    let sendBtn = 
      <button className="send-lead">
        שלח/י
      </button> 
    if ( this.state.isSendingForm === true ) {
      sendBtn = 
        <span>
          טוען... 
        </span>
    }  
    return (

      <div className="LeadForm-form">
 	 	    <form  onSubmit={this.handleSubmit}>
          <input type="hidden" name="cmp_code" value={this.props.cmpCode} />
          <div className="form-fields-wrapper">
            { formFields }
          </div>
          <div className="clear"></div>
          {sendBtn}
          
          {showErrHTML}
        </form>
      </div>
    );
  }
}

export default LeadForm;
