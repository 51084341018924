import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import Http from '../../Axios/Axios';

import './SiteLogos.css';
import { getLogos } from '../../Utility/Utility';
import { SiteLogo } from './SiteLogo'



class SiteLogos extends Component {
  state = {
  	logos: []
  }
  constructor(props){
    super(props);

  	
  }

  componentDidMount () {
    var logos = getLogos().then( (logos) => {
      console.log(logos);
      this.setState({logos})   
    });
  }


  render() {
  	const logos = this.state.logos.map((o , i)=>{
  		return  <SiteLogo link={o.WP_logo_link}  img={o.WP_logo_img} />
  	})
    return (
      <div className="Site-logos">
     	 	<span>
         	 	בשיתוף עם:
    	 	</span>
    	 	{logos}
      </div>
    );
  }
}

export default SiteLogos;
