import React  from 'react';
import { Link } from 'react-router-dom'
import { getImgUrl, limitText , isMobile , getUrl } from "../../Utility/Utility"
import SiteLogos from '../SiteLogos/SiteLogos';
import HeaderDate from '../HeaderDate/HeaderDate';
import SerachForm from '../SerachForm/SerachForm';
import SiteScan from '../SiteScan/SiteScan';


import SimpleSlider from '../SimpleSlider/SimpleSlider';
import "./UI.css"


export const SingleComment = ( props ) => {
  return(
    <div className="Comment-component">
      <p>
      <span className="counter">
        {props.counter}.
      </span> 
      {props.comment}
      </p>
      <span>
        {props.author_name}
      </span>
     </div>
  )
}

export const TopPart = () => {
  let HeaderDateHTML = <div className="row display-flex space-between align-center">
                            <HeaderDate />
                            <Logo />
                          </div>;
  let  HeaderDateMobile = null;

  if ( isMobile() ) {

    HeaderDateMobile = <div className="row display-flex space-between align-center">
                              <Logo />
                              <HeaderDate />
                            </div>
    HeaderDateHTML = null;
  }
                          
  return(
    <div className='top-part'>
      <div className='container'>
        {HeaderDateHTML}
        <div className="clear"></div>
        <div className="white-border hide-on-mobile"></div>
        <div className="clear"></div>
        {HeaderDateMobile}
        <div className="row">
          <div className="col col-80">
            <SiteLogos />
          </div>
          <div className="col col-20">
            <SerachForm />
          </div>
          <div className="clear"></div>
          <SiteScan />
        </div>

        <div className="clear"></div>
      </div>
    </div>
  )
}

export const Input = ( props ) => {
  let name =  props.name;
  let labelProp =  props.label;

  let cssWrapperClass = 'form-input-wrapper input-' +  name + '-wrapper ' + props.inputCssClass;
  let cssInputClass = ' input-'+name +' '  ;
  let labelField  = '';
  let id = "ac-input-" + new Date().getTime();
  
  if ( labelProp !== undefined ) {
    cssWrapperClass += ' inputHasLabel '
    labelField = <label htmlFor={id}> {props.label} </label>
  }
  let  html =  <div className={cssWrapperClass} >
                {labelField}
                <input id={id} data-validation-type={props.validationType} onClick={props.click} onChange={props.change} type={props.type} name={name} className={cssInputClass} placeholder={props.placeholder} value={props.val}/>
              </div>
  if ( props.type === "hidden" ) {
    html =  <input  type={props.type} name={name} className={cssInputClass} placeholder={props.placeholder} value={props.val}/>
  }
  return (  html   );
};

export const Select =  ( props ) => {
  let name = props.name;
  let cssWrapperClass = 'form-input-wrapper input-' +  name + '-wrapper select-' +  name + '-wrapper' + props.cssClass;
  let cssInputClass = 'select-'+name +' '  ;
  let labelProp = props.label;
  let labelField  = '';
  let id = "ac-input-" + new Date().getTime();
  
  if ( labelProp !== undefined ) {
    cssWrapperClass += ' inputHasLabel '
    labelField = <label htmlFor={id}> {props.label} </label>
  }
  return (
    <div className={cssWrapperClass}>
      {labelField}
      <select onChange={props.change} name={props.name} className={cssInputClass}>
       {props.children};
      </select>
    </div>
  )
}


export const Img = (props) => {

  let desktopImg  = ( props.desktopImg ) ?  getImgUrl() + "" + props.desktopImg : "" ;
  let mobileImg   = ( props.mobileImg )   ?  getImgUrl() + "" + props.mobileImg : "" ;
 

  const cssClass  =  ( props.cssClass  !== undefined  )   ?  props.cssClass : "" ;
  
  const isExternalLink = ( props.isExternalLink !== undefined )   ? props.isExternalLink : false ;
  
  if ( isExternalLink ) {
    desktopImg = ( props.desktopImg ) ?  props.desktopImg : "" ;
    mobileImg = ( props.mobileImg )   ?  props.mobileImg: "" ;
  }
  let html = 
    <div className={'img-wrapper ' + cssClass}>
      <img onClick={props.click} src={desktopImg} alt=""/>
    </div>

  if ( props.mobileImg !== undefined ) {
    if ( isMobile() ) {
      html = 
          <div className={'img-wrapper ' + cssClass}>
            <img onClick={props.click} className="show-on-mobile" src={mobileImg} alt=""/>
          </div>

    }
  }
  return (html)
}

export const Logo = () => {
  return(
    <div className="logoWrapper">
      <a  href={getUrl()}>
        <Img desktopImg={"logo.png"}   />
      </a>
  
    </div>
  )
}


export const BannerIframe =(props)=>{ 
  
  return(
    <div className="Banner-iframe">
        <iframe  src={props.src} height={props.height} width={props.width} allowfullscreen="" frameborder="0">
        </iframe>
        <button onClick={props.click} ></button>
    </div>
  )
}


export const ResponsiveYouTubeIframe =(props)=>{ 
  const videoSrc = 'https://www.youtube.com/embed/'+props.vidId+'/?autoplay=1&mute=1&rel=0&loop=1&playlist=' + props.vidId;
  return(
    <div className="responisve-iframe">
        <iframe src={videoSrc} height="315" width="560" allowfullscreen="" frameborder="0">
        </iframe>
    </div>
  )
}


export const ReadMore  = (props) => {
   return(
    <div className="read-more-wrapper">
      <a  target="_blank" className="read-more" href={props.link} >
        קרא עוד
      </a>
    </div>
  )
}



export const HomePostItem = (props) => {

  let imgToShow = ( props.imgToShow !== undefined ) ? props.imgToShow : '' ;;
  let link      = ( props.link !== undefined ) ? props.link : '' ;
  let limitTextLength = 150;
  let limitTitleLength = 80;
  const ctgLink = ( props.ctgLink !== undefined ) ? props.ctgLink : '' ;
  const cssClass  = ( props.cssClass !== undefined ) ? props.cssClass : '' ;
  const  isExternalLink = ( props.isExternalLink !== undefined ) ? props.isExternalLink : '' ;
  const id = ( props.id !== undefined ) ? props.id : '' ;
  const date_post_created = ( props.date_post_created !== undefined ) ? props.date_post_created : '' ;
  let site = ( props.site !== undefined ) ? props.site : '' ;
  if ( isExternalLink === false ) {
    link = '/Posts/' +id;
  }else {

    var mark = '?';
    
    var patt = new RegExp(/\?/g);
    var res = patt.test(link);
    if ( res === true ) {
      mark = "&";
    }
    link += mark + 'ref=realestate-news.co.il';
  }
  // alert(id);
  // alert(cssClass);

  if ( cssClass === 'home-post-full' ) {
    limitTextLength = 150
  }

  if ( site === '' ) {
    site = 'realestate';
  }
  const imgCssClass ='main-img-cover';
  return(
    <div className={"border-raduis-box  home-post " + cssClass}>
      <div className="inner">
        <a href={link} target="_blank">
          <Img cssClass={imgCssClass} desktopImg={imgToShow} isExternalLink ={true} />
        </a>
        <div className="post-info">
          <div className="post-info-inner">
            <div className="post-text-wrapper">
              <a href={link} target="_blank">
                <h2>
                  {limitText(props.title , limitTitleLength)}
                </h2>
                <p>
                  {limitText(props.content , limitTextLength)}
                </p>
              </a>
            </div>
            
            <div className="bottom-post-info display-flex space-between align-center">
              <div className="post-date">
                {date_post_created}
              </div>
              <ReadMore link={link} />
              <div className={'site-brand logo-' + site}>
                <a href={ctgLink}>
                  <Img desktopImg={'siteLogos/' + site + '.png' }  />
                </a>
              </div>
            </div>
          </div>
        </div>

      </div>
      
    </div>
  )
}